<template>
  <div>
    <v-row class="text-right">
      <v-col cols="12" md="4" class="d-flex align-center flex-row">
        <CustomerSearch ref="CustomerSearch" @input="setCustomer($event)" />
        <div v-if="searchParams.customer_id">
          <app-icon @click.stop="handleOpenCustomerForm()" color="info">
            north_east
          </app-icon>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-text class="pa-2">
            <v-row class="text-center">
              <v-col cols="6" md="3">
                <b> T. Original: </b> {{ $format.moneyBr(totals.value_total) }}
              </v-col>
              <v-col cols="6" md="3">
                <b>T. Pago: </b>
                {{ $format.moneyBr(totals.paid_total - totals.discount_total) }}
              </v-col>
              <v-col md="3">
                <b>T. Descontos: </b>
                {{ $format.moneyBr(totals.discount_total) }}
              </v-col>
              <v-col cols="6" md="3">
                <b>T. Juros PG: </b>
                {{ $format.moneyBr(totals.interest_paid_total) }}
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col cols="6" md="3">
                <b>Vencidas: </b>{{ $format.moneyBr(totals.net_overdue_total) }}
              </v-col>
              <v-col cols="6" md="3">
                <b>Saldo Dev: </b> {{ $format.moneyBr(totals.remainder_total) }}
              </v-col>
              <v-col cols="6" md="3">
                <b>Juros a PG: </b>
                {{ $format.moneyBr(totals.interest_to_pay) }}
              </v-col>
              <v-col cols="6" md="3">
                <b>Media de Dias: </b>{{ $format.decimal(totals.average_days) }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col>
        <v-data-table
          v-if="!isMobile"
          :headers="headers"
          :items="bill_receivables"
          :server-items-length="countBillReceivable"
          hide-default-footer
          disable-sort
          dense
          :mobile-breakpoint="-1"
        >
          <template v-slot:[`item.document`]="{ item }">
            <span v-if="item.origin == 'sale'">
              {{ item.sale.number }}
            </span>
            <span v-else>
              {{ item.document }}
            </span>
          </template>
          <template v-slot:[`item.issue_date`]="{ item }">
            {{ $format.dateBr(item.issue_date) }}
          </template>

          <template v-slot:[`item.due_date`]="{ item }">
            <app-due-date-label :due-date="item.due_date" />
          </template>

          <template v-slot:[`item.value`]="{ item }">
            {{ $format.moneyBr(item.value) }}
          </template>

          <template v-slot:[`item.paid_value`]="{ item }">
            {{ $format.moneyBr(item.paid_value - item.discount) }}
          </template>

          <template v-slot:[`item.discount`]="{ item }">
            {{ $format.moneyBr(item.discount) }}
          </template>

          <template v-slot:[`item.remainder_value`]="{ item }">
            {{ $format.moneyBr(item.remainder_value) }}
          </template>

          <template v-slot:[`item.interest`]="{ item }">
            {{ $format.moneyBr(item.interest) }}
          </template>

          <template v-slot:[`item.interest_to_pay`]="{ item }">
            {{ $format.moneyBr(item.interest_to_pay) }}
          </template>
        </v-data-table>

        <v-list v-if="isMobile" dense subheader two-line>
          <v-list-item
            v-for="(bill, index) in bill_receivables.data"
            :key="index"
            class="d-flex justify-end"
          >
            <v-list-item-content>
              <v-list-item-title>
                Doc :
                <span v-if="bill.origin == 'sale'">
                  {{ bill.sale.number }}
                </span>
                <span v-else>
                  {{ bill.document }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle
                :class="[bill.days_late > 0 ? 'red--text' : '']"
              >
                Dias {{ $format.dateBr(bill.days_late) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-subtitle>
                <small v-if="bill.days_late > 0" class="mr-1">
                  (Juros: {{ $format.moneyBr(bill.interest) }})
                </small>
                {{ $format.moneyBr(bill.value) }}
              </v-list-item-subtitle>

              <v-list-item-subtitle>
                Pago: {{ $format.moneyBr(bill.paid_value) }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </v-list>
      </v-col>
    </v-row>
    <StoreCustomerForm ref="StoreCustomerForm" />
  </div>
</template>

<script>
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";
import StoreCustomerForm from "@/components/customer/forms/StoreCustomerForm.vue";

export default {
  components: {
    CustomerSearch,
    StoreCustomerForm,
  },
  data: () => ({
    isLoading: false,

    customer: {},

    headers: [
      { text: "Doc", value: "document", align: "center" },
      { text: "Emissão", value: "issue_date", align: "center" },
      { text: "Vencimento", value: "due_date", align: "center" },
      { text: "Dias", value: "days_late", align: "center" },
      { text: "Valor Original", value: "value", align: "center" },
      { text: "Valor PG", value: "paid_value", align: "center" },
      { text: "Desc", value: "discount", align: "center" },
      { text: "Juros PG	", value: "interest", align: "center" },
      { text: "Saldo Dev	", value: "remainder_value", align: "center" },
      { text: "Juros", value: "interest_to_pay", align: "center" },
    ],

    searchParams: {
      status: ["to_pay"],
    },

    bill_receivables: [],
    selected: [],
    totals: {
      bill_receivables: 0,
      value_total: 0,
      remainder_total: 0,
      paid_total: 0,
      discount_total: 0,
      average_days: 0,
      net_overdue_total: 0,
      interest_paid_total: 0,
    },
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    countBillReceivable() {
      return this.bill_receivables.length;
    },
  },

  methods: {
    select() {
      this.$http
        .index("bill-receivable/bill-receivable", this.searchParams)
        .then((response) => {
          this.bill_receivables = response.bill_receivables;
          this.totals = response;
        });
    },

    setCustomer(customer) {
      this.searchParams.customer_id = customer.id;
      this.select();
    },

    handleOpenCustomerForm() {
      this.$refs.StoreCustomerForm.open(this.searchParams.customer_id);
    },
  },
};
</script>

<style></style>
